import React, { Component } from "react";
import PropTypes from "prop-types";
import Test from "../../../../demo/src/public/FRAME01.jpg"; //nombre de la imagen a cargar test(número)
import Test1 from "../../../../demo/src/public/FRAME02.jpg";
import Test2 from "../../../../demo/src/public/FRAME03.jpg";
import Test3 from "../../../../demo/src/public/HEXA01.jpg";
import Test4 from "../../../../demo/src/public/HEXA02.jpg";
import Test5 from "../../../../demo/src/public/HEXA03.jpg";
import Panel from "../panel";
import { Tab, TabList, Tabs } from "react-tabs";
import { browserUpload } from "../../../utils/browser";
import sample from "../../../samples/Frame1.json";
import sample1 from "../../../samples/Frame2.json";
import sample2 from "../../../samples/Frame3.json";
import sample3 from "../../../samples/Hexa1.json";
import sample4 from "../../../samples/Hexa2.json";
import sample5 from "../../../samples/Hexa3.json";
import { FaPlus } from "react-icons/fa";

export default function LoadSample({ state }, { translator, projectActions }) {
  
  let handleClick = event => {
    event.preventDefault();
    projectActions.loadProject(sample);
  };
  let handleClick2 = event => {
    event.preventDefault();
    projectActions.loadProject(sample1);
  };
  let handleClick3 = event => {
    event.preventDefault();
    projectActions.loadProject(sample2);
  };
  let handleClick4 = event => {
    event.preventDefault();
    projectActions.loadProject(sample3);
  };
  let handleClick5 = event => {
    event.preventDefault();
    projectActions.loadProject(sample4);
  };


  const styles = {
    width: "410px",
    height: "",
  };

  const tabStyles = {
    border: "",
    margin:"40px"
  };

  return (
    <Panel name={"Cargar Diseños Frame"}>
      <table style={tabStyles}>
        <tr>
          <td>
            <img src={Test} style={styles} onClick={handleClick}></img>
          </td>
        </tr>
        <tr>
          <td>
            <img src={Test1} style={styles} onClick={handleClick2}></img>
          </td>
        </tr>
        <tr>        
          <td>
            <img src={Test2} style={styles} onClick={handleClick3}></img>
          </td>
        </tr>
        
      </table>
    </Panel>    
  );
}

LoadSample.propTypes = {
  state: PropTypes.object.isRequired,
};

LoadSample.contextTypes = {
  projectActions: PropTypes.object.isRequired,
  translator: PropTypes.object.isRequired,
};

import Sidebar from './sidebar';
import Panel from './panel';
import PanelLayers from './panel-layers';
import PanelLayerElement from './panel-layer-elements';
import PanelGuides from './panel-guides';
import LoadSample from './panel-load-sample/load-sample';
import LoadSample2 from './panel-load-sample2/load-sample2';
import PanelAcabados from './panel-acabados/panel-acabados';

export {
  Sidebar,
  Panel,
  PanelLayers,
  PanelLayerElement,
  PanelGuides,
  LoadSample,
  LoadSample2,
  PanelAcabados
};

export default {
  Sidebar,
  Panel,
  PanelLayers,
  PanelLayerElement,
  PanelGuides,
  LoadSample,
  LoadSample2,
  PanelAcabados
};

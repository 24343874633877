import axios from "axios";

export function sendEmail(data, objetoJSON) {
  const { email, name, phone, obsv, acabado } = data;

  const emailAngar = "info@angarmodular.com";

  const jsonStr = JSON.stringify(objetoJSON, null, 2);
  const jsonBlob = new Blob([jsonStr], {
    type: "application/json",
  });

  const formData = new FormData();
  formData.append("from", "noreply@angarmodular.com");
  formData.append("to", emailAngar);
  formData.append("subject", "Asunto del correo");
  formData.append(
    "text",
    `Nombre: ${name}, Email: ${email}, ${phone}, Observaciones: ${obsv}, Acabado: ${acabado}`
  );
  formData.append("attachment", jsonBlob, "modelo.json");

  axios({
    method: "post",
    url: `https://api.mailgun.net/v3/sandboxcf52f3edd9b64675a2fbaf417a41d85f.mailgun.org/messages`,
    data: formData,
    auth: {
      username: "api",
      password: "8c0f81874430611d2c0d9b48528a092b-3750a53b-f6e3be72",
    },
  })
    .then((response) => {
      if (response.status === 200) {
        console.log("Correo enviado con éxito");
        this.props.saveProject(data);
      }
    })
    .catch((error) => {
      console.error("Error al enviar correo", error);
    });
}

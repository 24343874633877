import {Catalog} from 'react-planner';

let catalog = new Catalog();

import * as Items from './items/**/planner-element.jsx';

for( let x in Items ) catalog.registerElement( Items[x] );
catalog.registerCategory('cama', 'CAMAS', [Items.cama_11,Items.cama_12,Items.cama_13] );
catalog.registerCategory('encimera', 'ENCIMERAS', [Items.encimera_21, Items.encimera_22, Items.encimera_23, Items.encimera_24, Items.encimera_25, Items.encimera_26, Items.encimera_27, Items.encimera_28] );
catalog.registerCategory('bano', 'BAÑOS', [Items.baño_31, Items.baño_32, Items.baño_33, Items.baño_34, Items.baño_35, Items.baño_36, Items.baño_37, Items.baño_38, Items.baño_39, Items.baño_310, Items.baño_311] );
catalog.registerCategory('espacios', 'ESPACIOS VACÍOS', [Items.espacios_41, Items.espacios_42, , Items.espacios_43, Items.espacios_44 ] );
catalog.registerCategory('pared', 'PAREDES', [Items.pared_51, Items.pared_52 ] );
catalog.registerCategory('puerven', 'PUERTAS Y VENTANAS', [Items.puerven_61, Items.puerven_62, Items.puerven_63, Items.puerven_64, Items.puerven_65, Items.puerven_66, Items.hex_puerta] );
catalog.registerCategory('armario', 'ALMACENAJE', [Items.almacenaje_71, Items.almacenaje_72] );
catalog.registerCategory('instalaciones', 'INSTALACIONES', [Items.instalaciones_81, Items.instalaciones_82, Items.instalaciones_83, Items.instalaciones_84, Items.instalaciones_85, Items.instalaciones_86, Items.instalaciones_87, Items.instalaciones_88, Items.instalaciones_89, Items.instalaciones_810] );
catalog.registerCategory('hexagonal', 'MÓDULOS HEXA', [Items.hex_cama_12, Items.hex_sofa_11, Items.hex_cocina_bano, Items.hex_paso_21, Items.hex_instalaciones] );
export default catalog;

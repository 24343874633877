import Imagen1 from "../../../../../demo/src/public/ACABADO1_ACERO.jpg"
import Imagen2 from "../../../../../demo/src/public/ACABADO2_FENOLICO.jpg";
import Imagen3 from "../../../../../demo/src/public/ACABADO3_FLORAL.jpg";
import Imagen4 from "../../../../../demo/src/public/ACABADO4_TEJA.jpg";
import Imagen5 from "../../../../../demo/src/public/HX_ACERO_CORTEN.jpg";
import Imagen6 from "../../../../../demo/src/public/HX_FENOLICO_BLANCO.jpg";
import Imagen7 from "../../../../../demo/src/public/HX_FLORAL.jpg";
const imagenes = [Imagen1, Imagen2, Imagen3, Imagen4, Imagen5,Imagen6,Imagen7];
//Para añadir o quitar una imagen de acabado, aquí tiene que añadirse el archivo del acabado y añadir Imagen 5, Imagen 6 si hiciesen falta más
export default imagenes;
